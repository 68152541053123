import React from 'react';
import { graphql } from 'gatsby';
import SEO from '@codedrop/seo/esm/seo';
import Header from '@codedrop/header/esm/header';
import Layout from '@codedrop/layout/esm/layout';
import Heading from '@codedrop/typography/esm/heading';
import Card from '@codedrop/card/esm/card';
import Cta from '@codedrop/cta/esm/cta';
import Footer from '@codedrop/footer/esm/footer';
import setHTML from '@codedrop/utils/esm/utils';
import data from '../data/skpr.json';

const { seo, header, footer, cta } = data;

export const query = graphql`
  query {
    allMdx(sort: { order: DESC, fields: fileAbsolutePath }) {
      nodes {
        frontmatter {
          path
          summary
          title
          date
          author
          tag
          tagColor
          tags {
            name
          }
        }
      }
    }
  }
`;

const Blog = ({ data }) => {
  const posts = data.allMdx.nodes;
  return (
    <div>
      <SEO title="Skpr Blog" seo={seo} />
      <Header header={header} />
      <main>
        <Layout className="background--firework section--top-l">
          <Heading tag="h1" headingClass="heading--special page-title">
            Articles
          </Heading>
          <div className="grid blog">
            {posts.map((content, contentIndex) => {
              const { tagColor, path, tag, title, summary, author } =
                content.frontmatter;

              return (
                <Card
                  cardClass={`grid__col grid--4-col card--${tagColor}`}
                  cardHeading=""
                  cardLinkUrl={path}
                  cardLinkText=""
                  cardTagText={tag}
                  cardTagClass={`tag--${tagColor}`}
                  key={contentIndex}
                >
                  <h2>{setHTML(title)}</h2>
                  <p>{setHTML(summary)}</p>
                  <p className="card__author">By {author}</p>
                </Card>
              );
            })}
          </div>
        </Layout>
        <Cta cta={cta} />
      </main>
      <Footer footer={footer} />
    </div>
  );
};

export default Blog;
